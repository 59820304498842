<template>
  <div class="cd__actions-wrapper sticky-main">
    <div class="cd__actions-container">
      <div
        class="cd__action-div"
        :class="
          getSelectedCourse.intro_video_url
            ? 'cd__video-url'
            : 'cd__video-no-url'
        "
      >
        <!-- video preview -->
        <div
          class="intro_video_url d-none d-lg-block"
          v-if="getSelectedCourse.intro_video_url"
          :style="{ 'background-image': 'url(' + youtubeThumbnail + ')' }"
        >
          <img
            src="@/assets/images/icons/play-blue.svg"
            alt="play"
            width="auto"
            height="auto"
            class="c-pointer"
            @click="$bvModal.show('intro-video-modal')"
          />
        </div>

        <div>
          <div class="title-register  center d-none d-lg-block">
            <a class="cd__register--link" href="javascript:;" @click="signUp">{{
              $t("general.register")
            }}</a>
            <span class="cd__start-learning">{{
              $t("general.to_start_learning")
            }}</span>
          </div>
          <div v-if="isTitleDisplay" class="title-register">
            {{ getSelectedCourse.title }}
          </div>

          <div class="cd__course-card">
            <Button
              @click="startLearning"
              variant="outline-primary"
              class="lp-button__height text-uppercase"
              :pill="true"
              :block="true"
            >
              {{ $t("course.enroll_now") }}
            </Button>
          </div>
        </div>

        <div class="cd__course-enrollment--style">
          <div class="cd__course-enrollment--div d-flex">
            <div class="cd__actions--icon">
              <img
                src="@/assets/images/courseware/school.svg"
                class="cd__image--style"
              />
            </div>
            <div class="cd__actions--text">
              <span> {{ $t("course.enroll_by") }} </span>
              <span class="cd__actions--date">{{
                getSelectedCourse.enrollment_ends_at
                  | formatDate("MMM Do, YYYY")
              }}</span>
            </div>
          </div>
        </div>

        <div class="cd__course-enrollment--div d-none d-lg-flex">
          <div class="cd__actions--icon">
            <img
              src="@/assets/images/courseware/self-paced.svg"
              class="cd__image--style"
            />
          </div>
          <div class="cd__actions--text-pacing">
            {{ $t(`data.pacing.${getSelectedCourse.pacing}`) }}
          </div>
        </div>

        <div class="cd__course-enrollment--div d-none d-lg-flex">
          <div class="cd__actions--icon">
            <img
              src="@/assets/images/courseware/translate.svg"
              class="cd__image--style"
            />
          </div>
          <div class="cd__actions--text-pacing">
            {{
              getSelectedCourse.language
                ? $t(`data.language.${getSelectedCourse.language}`)
                : $t(`data.language.en`)
            }}
          </div>
        </div>

        <div class="cd__course-enrollment--div d-none d-lg-flex">
          <div class="cd__actions--icon">
            <img
              src="@/assets/images/courseware/calendar.svg"
              class="cd__image--style"
              alt="calendar"
            />
          </div>
          <div class="cd__actions--text">
            <span v-if="hasFutureStartDate">{{
              $t("course.starting_on")
            }}</span>
            <span v-if="hasPastStartDate">{{ $t("course.started_on") }}</span>
            <span class="cd__actions--date">
              {{
                getSelectedCourse.course_starts_at | formatDate("MMM Do, YYYY")
              }}
            </span>
            <span v-if="hasFutureEndDate">{{ $t("course.ending_on") }} </span>
            <span v-if="hasPastEndDate">{{ $t("course.ended_on") }}</span>
            <span class="cd__actions--date">
              {{
                getSelectedCourse.course_ends_at | formatDate("MMM Do, YYYY")
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="cd__actions-share--text">
      <div v-if="showAlert" class="alert">
        <img src="@/assets/images/courseware/copy-check.svg" />
        {{ $t("course.copied_to_clipboard") }}
      </div>
      <div v-else class="cd__copy--text" @click="copyUrl">
        <img src="@/assets/images/courseware/share.svg" />
        <span class="cd__actions--share">
          {{ $t("course.share") }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
// utils
import utils from "@/router/utils";
import ctaActionsMixin from "@/components/CourseDetail/mixins/ctaActions";
export default {
  components: {},
  props: {
    isTitleDisplay: {
      type: Boolean,
      required: true
    }
  },
  mixins: [ctaActionsMixin],
  data() {
    return {
      showAlert: false
    };
  },
  computed: {
    ...mapGetters(["getSelectedCourse", "language", "allConfig"]),
    hasFutureStartDate() {
      return moment(new Date()).isBefore(
        moment(this.getSelectedCourse.course_starts_at)
      );
    },
    hasPastStartDate() {
      return moment(new Date()).isAfter(
        moment(this.getSelectedCourse.course_starts_at)
      );
    },
    hasFutureEndDate() {
      return moment(new Date()).isBefore(
        moment(this.getSelectedCourse.course_ends_at)
      );
    },
    hasPastEndDate() {
      return moment(new Date()).isAfter(
        moment(this.getSelectedCourse.course_ends_at)
      );
    }
  },
  methods: {
    startLearning() {
      this.$keycloak.login({
        redirectUri: utils.getRedirectUri(),
        locale: this.language
      });
    },
    signUp() {
      this.$keycloak.login({
        redirectUri: this.redirectUri,
        action: "register",
        locale: this.language
      });
    }
  }
};
</script>
<style lang="scss">
.cd__actions-wrapper {
  .cd__actions-container {
    .cd__action-div {
      background: $brand-neutral-0;
      .intro_video_url {
        margin-bottom: 0px;
        margin-top: 0px;

        img {
          position: absolute;
          top: 40%;
          left: 45%;
        }
      }
    }

    .cd__course-card {
      padding: 1rem;
    }

    .title-register {
      @include label-large;
      font-weight: 500;
      align-items: center;
      text-align: center;
      padding-top: 16px;
    }
    .cd__register--link {
      color: $brand-primary-400;
      text-decoration: underline;
    }
    .cd__start-learning {
      color: $brand-primary;
      margin: 5px;
    }
  }

  .cd__custome-button--style {
    padding-top: 16px;
    padding-bottom: 16px;
    border-color: transparent;
    width: 205px;
    white-space: nowrap !important;
    height: 56px !important;
  }

  .cd__enroll-disable--style {
    background: $brand-neutral-75;
    color: $brand-neutral-200;
    border-color: transparent;
  }

  .cd__enrolled--style {
    color: $brand-neutral-75;
    background: $brand-primary-700;
  }

  .cd__resume--style {
    color: $brand-neutral-75;
    background: $brand-primary-400;
  }

  .cd__review-button--style {
    background: $brand-neutral-0;
    color: $brand-primary;
    border: 1px solid $brand-primary !important;
  }

  .cd__course-enrollment--style {
    @include label-small;

    .cd__img-div {
      padding-right: 4px;
      padding-top: 10px;
    }
  }
}

.cd__course-enrollment--style {
  @include label-small;

  .cd__img-div {
    padding-right: 4px;
    padding-top: 10px;
  }
}

.cd__image--style {
  padding-left: 20px;
}

.cd__course-enrollment--div {
  padding-bottom: 16px;

  .cd__actions--text {
    @include label-small;
    color: $brand-neutral-900;
    text-align: left;
    padding-right: 10px;
  }

  .cd__actions--text-pacing {
    @include label-small;
    color: #222222;
    text-align: left;
  }

  .cd__actions--date {
    font-weight: 400;
    padding-left: 2px;
  }
}

.cd__actions--icon {
  @include flex-horizontal-center;
  padding-right: 10px;
}

.cd__actions--text-success {
  @include label-small;
  color: #00c781;
}

.cd__actions-share--text {
  text-align: center;
  padding-top: 14px;

  .alert {
    @include label-large;
    text-align: center;
    letter-spacing: 0.25px;
    color: $brand-neutral-800;
    padding: 8px;
    gap: 8px;
  }

  .cd__copy--text {
    @include body-medium;
    color: $brand-primary-400;
    border-radius: 100px;
    cursor: pointer;

    .cd__actions--share {
      margin-left: 10px;
    }
  }
}

.cd__actions--date {
  margin-left: 2px;
  margin-right: 5px;
}

.cd__actions--calendar {
  padding-top: 10px;
}

.cd__actions--review {
  background: $brand-neutral-0;
  color: $brand-primary;
  border: 1px solid $brand-primary !important;
}

.lp-calendar {
  @include label-small;
  font-weight: 400;
  text-align: left;
}

.lp-copy-text {
  @include label-large;
  text-align: center;
  letter-spacing: 0.25px;
  color: $brand-neutral-800;
  padding: 8px;
  gap: 8px;
}

.lp-share-text {
  padding: 8px;
  gap: 8px;
  width: 77px;
  height: 37px;
  @include body-medium;
  color: $brand-primary-400;
  border-radius: 100px;
}

.title-register {
  text-align: left;
  color: $brand-public-primary;
  @include body-regular;
  letter-spacing: 0.15px;
  font-weight: 500;
}

.intro-video-image {
  position: absolute;
  top: 40%;
  left: 45%;
}

.lp-button__height {
  padding-top: 16px;
  padding-bottom: 16px;
  border-color: transparent;
  white-space: nowrap !important;
  height: 56px !important;
  color: $brand-neutral-75 !important;
  background: $brand-primary !important;
}

.cd__course-card {
  padding: 1rem;
}

@mixin content-pacing {
  @include label-large;
  font-weight: 500;
  line-height: 26px;
  padding-left: 10px;
}

.cd__actions-share_text {
  text-align: center;
  padding-top: 16px;
}

// rtl layout
[dir="rtl"] {
  .cd__actions-container {
    .cd__course-enrollment--div {
      .cd__actions--text {
        padding-right: 0px;
        padding-left: 10px;
      }
      .cd__actions--date {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .cd__action-div {
      text-align: right;

      span {
        &.content__text {
          padding: 0 10px 0 0;
        }

        &.content__date {
          padding: 0 5px 0 0;
        }
      }
    }
  }

  .cd__image--style {
    padding-left: 0px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sticky-main {
    width: 100% !important;
  }
}

@media screen and (max-width: 786px) {
  .cd-box__pacing {
    display: none;
  }
}
</style>
